<template>
  <v-card
      class="mx-auto my-0"
      min-width="240"
  >
    <v-img
        width="240"
        height="80"
        :src="system.logoUrl"
    ></v-img>

    <v-card-title>{{ system.name }}</v-card-title>

    <v-card-text>
      <div class="my-2 text-subtitle-1" style="line-height: 0">
        Tipos de envío:
      </div>

      <div class="col-lg-4 col-sm-12 my-0 text-subtitle-3" v-for="(item) in system.shipmentTypes" style="line-height: 0">
        - {{ item.name }}
      </div>

    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Vos nos podés enviar:</v-card-title>

    <v-card-text>
      <div>
        <v-chip-group
            v-model="selected"
            active-class="green accent-4 white--text"
            column
        >
          <v-chip
              v-for="(chip, index) in chips"
              :key="index"
              @click="selectChip(chip)"
          >
            {{ chip.currency.abbreviation }}
          </v-chip>
        </v-chip-group>
        <p>{{ text }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import System from "../../model/System";
import Rate from "../../model/Rate";

export default {
  name: 'ExchangeCard',

  props: {
    rates: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof Rate);
      },
    },

    system: {
      type: System,
      required: true,
    },

    systemList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    chips: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    }
  },

  components: {},

  data: () => ({
    selected: null,
    text: '',
  }),

  beforeMount() {
    this.selected = this.chips[0];
  },

  methods: {
    selectChip(chip) {
      this.selected = chip;
      const rate = this.rates.find(rate => rate.systemTo.id === this.selected.id).ranges[0]
      this.text = rate.buildExampleText()
    },
  },
}
</script>