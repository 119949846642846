export default class OrderData {
    constructor(rate, price, sendAmount, receiveAmount, localUserData, localShipmentData, selectedShipment, office, referralCode, accountData) {
        this.rate = {
            id: rate.id,
            price: price,
            send_amount: Number(sendAmount).toFixed(2),
            receive_amount: Number(receiveAmount).toFixed(2),
            shipment_id: selectedShipment.id,
            referral_code: referralCode,
        }

        /*this.rate_id = rate.id;
        this.rate_price = rate.price;
        this.send_amount = Number(sendAmount).toFixed(2);
        this.receive_amount = Number(receiveAmount).toFixed(2);
        this.shipment_id = selectedShipment.id;*/

        this.client = {
            name: localUserData.clientName,
            whatsapp: localUserData.phone,
            email: localUserData.clientMail,
        }

        /*this.client_name = localUserData.clientName;
        this.whatsapp = localUserData.phone;
        this.client_mail = localUserData.clientMail;*/

        if(selectedShipment.id === 1) {
            this.address = {
                label: localShipmentData.address + ' - ' + localShipmentData.addressComments,
                receiver: localShipmentData.addressReceiver,
                phone: localShipmentData.addressPhone,
                lat: localShipmentData.addressLat,
                lng: localShipmentData.addressLng,
                price: localShipmentData.addressPrice,
                date: localShipmentData.withdrawalDate,
            }
        }

        /*this.address = this.shipment_id === 1 ? localShipmentData.address + ' - ' + localShipmentData.addressComments : null;
        this.address_receiver = this.shipment_id === 1 ? localShipmentData.addressReceiver : null;
        this.address_telephone = this.shipment_id === 1 ? localShipmentData.addressPhone : null;
        this.address_lat = this.shipment_id === 1 ? localShipmentData.addressLat : null;
        this.address_lng = this.shipment_id === 1 ? localShipmentData.addressLng : null;
        this.address_price = this.shipment_id === 1 ? localShipmentData.addressPrice : null;*/

        if(selectedShipment.id === 2) {
            this.transfer = {
                bank: accountData.transferBank,
                holder: accountData.transferHolder,
                alias: accountData.transferAlias,
                cuit: accountData.transferCuit,
                cbu: accountData.transferCbu,
                account_number: accountData.transferAccountNumber,
            }
        }

        /*this.transfer_bank = this.shipment_id === 2 ? accountData.transferBank : null;
        this.transfer_holder = this.shipment_id === 2 ? accountData.transferHolder : null;
        this.transfer_alias = this.shipment_id === 2 ? accountData.transferAlias : null;
        this.transfer_cuit = this.shipment_id === 2 ? accountData.transferCuit : null;
        this.transfer_cbu = this.shipment_id === 2 ? accountData.transferCbu : null;
        this.transfer_account_number = this.shipment_id === 2 ? accountData.transferAccountNumber : null;
        this.wallet_adress = this.shipment_id === 4 ? accountData.type + ': ' + accountData.walletAddress : null;*/

        if(selectedShipment.id === 3) {
            this.account = {
                email: accountData.accountEmail,
            }
        }

        //this.account_email = this.shipment_id === 3 ? accountData.accountEmail : null;

        if(selectedShipment.id === 4) {
            this.wallet = {
                network: accountData.type,
                address: accountData.walletAddress,
            }
        }

        if(selectedShipment.id === 5) {
            this.withdrawal = {
                region: office,
                date: localShipmentData.withdrawalDate,
            }
        }

        //this.withdrawal_datetime = localShipmentData.withdrawalDate;

        //this.region = office;
        //this.client_referral_code = referralCode;
    }
}