<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <v-col class="px-0">
      <validated-outlined-text-input
          :model="localUserData.clientName"
          @change="nameUpdated"
          label="Nombre y Apellido"
          required
      />
      <phone-number-input
          @change="phoneUpdated"
          label="Teléfono"
          :model="localUserData.phone"
          :area-code="localUserData.areaCode"
      />
      <validated-mail-input
          :model="localUserData.clientMail"
          @change="mailUpdated"
          label="Email"
          hint="Vamos a contactarte por este medio"
          required
          important
      />
      <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Método de envío"
      >
        <v-select
            :items="shipmentTypeList"
            v-model="selectedShipment"
            label="Método de envío"
            item-text="name"
            item-value="id"
            class="mx-auto"
            dense
            outlined
            required
            :error-messages="errors"
            @change="shipmentTypeUpdated"
        ></v-select>
      </validation-provider>
      <shipment-form
          v-if="selectedShipment != null"
          :shipment-type="selectedShipment"
          :shipment-data="localShipmentData"
          :accounts-data="localAccountsData"
          @dateTimeUpdated="dateTimeUpdated"
      />
      <v-row class="mt-5 justify-space-between">
        <v-col
            align="end"
            justify="end">
          <v-btn
              class="mr-0"
              type="submit"
              color="primary"
              @click="submitForm"
              :disabled="invalid || !validAmounts"
          >
            Iniciar Pedido
            <v-icon
                dark
                right
            >
              mdi-checkbox-marked-circle
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </validation-observer>
</template>

<script>

import ShipmentType from "../../model/ShipmentType";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ShipmentForm from "./shipment/ShipmentForm";
import UserData from "../../model/UserData";
import ShipmentData from "../../model/ShipmentData";
import Swal from "sweetalert2";
import axios from "axios";
import OrderData from "../../model/OrderData";
import ValidatedOutlinedTextInput from "../Form/ValidatedOutlinedTextInput";
import PhoneNumberInput from "../Form/PhoneNumberInput";
import ValidatedMailInput from "../Form/ValidatedMailInput";
import TermsCheckbox from "./TermsCheckbox";
import router from "../../router";
import AccountData from "../../model/AccountData";

export default {
  name: 'DataForm',

  props: {
    userData: {
      required: false,
      validator: function (value) {
        return value instanceof UserData;
      },
    },

    shipmentData: {
      required: false,
      validator: function (value) {
        return value instanceof ShipmentData;
      },
    },

    accountsData: {
      type: Array,
      required: false,
      validator: function (value) {
        return value.every(item => item instanceof AccountData);
      },
    }
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    ValidatedOutlinedTextInput,
    PhoneNumberInput,
    ValidatedMailInput,
    ShipmentForm,
    TermsCheckbox,
  },

  data: () => ({
    selectedShipmentId: null,
    selectedShipment: null,
    localUserData: null,
    localShipmentData: null,
    localAccountsData: [],
    validAmounts: true,

    shipmentTypeList: {
      type: Array,
      validator: function (value) {
        return value.every(item => item instanceof ShipmentType);
      },
    },
  }),

  beforeMount() {
    this.shipmentTypeList = this.$store.state.shipment_type_list

    this.validAmounts = this.$store.state.valid_amounts

    if(this.userData != null) {
      this.localUserData = this.userData
    } else {
      this.localUserData = UserData.Blank()
    }

    if(this.shipmentData != null) {
      this.localShipmentData = this.shipmentData
    } else {
      this.localShipmentData = ShipmentData.Blank()
    }

    if(this.accountsData != null) {
      this.localAccountsData = this.accountsData
    }

    this.initializeSelectedShipment()
  },

  mounted() {},

  methods: {
    initializeSelectedShipment() {
      if(this.shipmentTypeList.length === 1) {
        this.selectedShipment = this.shipmentTypeList[0]
        this.selectedShipmentId = this.selectedShipment.id
      } else {
        this.selectedShipment = null
        this.selectedShipmentId = null
      }
    },

    nameUpdated: function(payload) {
      this.localUserData.clientName = payload
    },

    phoneUpdated: function(payload) {
      this.localUserData.phone = payload.formattedNumber.replace('+','')
    },

    mailUpdated: function(payload) {
      this.localUserData.clientMail = payload
    },

    shipmentTypeUpdated: function(payload) {
      this.selectedShipment = this.shipmentTypeList.find(system => system.id === payload);
      this.selectedShipmentId = this.selectedShipment.id
      this.localShipmentData.withdrawalDate = null
    },

    dateTimeUpdated(payload) {
      this.localShipmentData.withdrawalDate = payload
    },

    shipmentDataUpdated(payload) {
      this.localShipmentData = payload
    },

    submitForm() {
      const rate = this.rate
      const accountData = this.accountData
      if (accountData !== null) accountData.type = this.accountType
      const price = this.price
      const order = new OrderData(
        rate,
        price,
        this.sendAmount,
        this.receiveAmount,
        this.localUserData,
        this.localShipmentData,
        this.selectedShipment,
        this.$store.state.office,
        this.referralCode,
        accountData
      )

      Swal.fire({
        title: 'Procesando...',
        html: 'Estamos dando de alta tu pedido.',
        allowOutsideClick: false,
        scrollbarPadding: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      axios
          .post(process.env.VUE_APP_API_URL + '/api/orders', order)
          .then(response => {
            if(response.status === 201 || response.status === 204) {
              Swal.close();
              router.push("Confirmation");
            } else {
              Swal.fire({
                title: '¡Oops!',
                html: 'Hubo un error al procesar tu pedido. Por favor, intentá de nuevo.',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                scrollbarPadding: false,
              });
            }
          })
          .catch(e => {
            console.log(e)
            Swal.fire({
              title: '¡Oops!',
              html: 'Hubo un error al procesar tu pedido. Por favor, intentá de nuevo.',
              icon: 'error',
              showConfirmButton: false,
              timer: 5000,
              scrollbarPadding: false,
            });
          });
    }
  },

  computed: {
    rate () {return this.$store.state.selected_rate;},
    price() {return this.$store.state.price},
    sendAmount () {return this.$store.state.send_amount;},
    receiveAmount () {return this.$store.state.receive_amount;},
    referralCode () {return this.$store.state.referral_code},
    accountData () {return this.$store.state.account_data},
    accountType () {return this.$store.state.account_type}
  },

  watch: {
    "$store.state.valid_amounts"(nv) {
      this.validAmounts = nv;
    },

    "$store.state.shipment_type_list"(nv) {
      this.shipmentTypeList = nv
      if(!this.shipmentTypeList.some(type => type.id === this.selectedShipmentId)) {
        this.initializeSelectedShipment()
      }
    },
  },
}
</script>
