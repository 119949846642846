<template>
  <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-6 mx-auto py-10 px-4 rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>

import System from "../../model/System";
import Rate from "../../model/Rate";

export default {
  name: 'ExchangeMatrix',

  props: {
    rates: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof Rate);
      },
    },

    systems: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    title: {
      type: String,
    },

    firsColumnHeader: {
      type: String,
    },

    rows: {
      type: Array,
      default: [],
      required: true,
    },

    columns: {
      type: Array,
      default: [],
      required: true,
    },

    headerType: {
      type: String,
      default: 'abbreviation',
      required: true,
    },
  },

  components: {},

  data: () => ({
    headers: [],
    items: [],
  }),

  beforeMount() {
    this.populateRates();
  },

  methods: {
    populateRates() {
      this.items = []
      this.headers = []

      const filteredSystemsFrom = this.systems.filter(system => this.rows.includes(system.id))
      const filteredSystemsTo = this.systems.filter(system => this.columns.includes(system.id))

      const firstHeader = {
        text: this.firsColumnHeader,
        value: 'name',
      }

      this.headers.push(firstHeader)
      filteredSystemsTo.map(system =>
      {
        const header = {
          text: this.headerType === 'name' ? system.name : system.currency.abbreviation,
          align: 'center',
          value: system.name,
        }
        this.headers.push(header)
      })

      filteredSystemsFrom.map((row) => {
        const item = {
          name: this.headerType === 'name' ? row.name : row.currency.abbreviation,
          ...filteredSystemsTo.reduce((acc, column) => {
            try {
              const selectedRate = this.rates.find(rate => rate.systemFrom.id === row.id && rate.systemTo.id === column.id).ranges[0];
              acc[column.name] = selectedRate.price
            } catch (e) {
              acc[column.name] = '-'
            }
            return acc
          }, {})
        }
        this.items.push(item)
      })
    },
  },
}
</script>