<template>
  <v-col cols="12">
    <br>
    <div class="text-field-header pl-4">
      Vos enviás:
    </div>
    <br>
    <responsive-card>
      <system-form
          :selected-system="selectedSystem"
          :system-list="systemList"
          @amountUpdated="amountFromUpdated"
          @selectedSystemUpdated="selectedSystemFromUpdated"
          :amount="this.roundDecimals(amountFrom,2)"
          @amountBlurred="amountFromBlurred"
          :min="this.roundDecimals(selectedRate.minAmountToReceive(),2)"
          :max="this.roundDecimals(selectedRate.maxAmountToReceive(),2)"
          :key="forceUpdateKey"
      />
      <tax-indicator :tax="selectedSystem.tax" :abbreviation="selectedRate.systemFrom.currency.abbreviation" />
      <v-col>
        <v-row v-if="this.selectedRate.isBetterRange(this.amountFrom)" justify="center">
          <span style="font-size: 0.875rem" :key="forceUpdateKey">
            <v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>
            {{ this.selectedRate.getSuggestionText(this.amountFrom) }}
          </span>
        </v-row>
      </v-col>
    </responsive-card>
    <br>
    <v-row align="center" justify="center">
      <v-icon
          white
          x-large
          center
          class="color-primary"
      >
        mdi-arrow-down
      </v-icon>
    </v-row>
    <br>
    <br>
    <div class="text-field-header ml-4">
      Nosotros te enviamos:
    </div>
    <br>
    <responsive-card>
      <system-form
          :selected-system="systemTo"
          :system-list="systemToList"
          @amountUpdated="amountToUpdated"
          @selectedSystemUpdated="selectedSystemToUpdated"
          :amount="this.roundDecimals(amountTo,2)"
          @amountBlurred="amountFromBlurred"
          :min="this.roundDecimals(selectedRate.minAmountToSend(),2)"
          :max="this.roundDecimals(selectedRate.maxAmountToSend(),2)"
          :key="forceUpdateKey"
      />
    </responsive-card>
    <br>
    <br>
  </v-col>
</template>

<script>
import System from "../../model/System";
import SystemForm from "../amounts/SystemForm";
import Rate from "../../model/Rate";
import ResponsiveCard from "../Common/ResponsiveCard";
import TaxIndicator from "../amounts/TaxIndicator";
import ExampleIndicator from "../amounts/ExampleIndicator";
import Swal from "sweetalert2";
import ShipmentType from "../../model/ShipmentType";

export default {
  name: 'AmountForm',

  props: {
    systemList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    rateList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof Rate);
      },
    },
  },

  components: {
    SystemForm,
    ResponsiveCard,
    TaxIndicator,
    ExampleIndicator,
  },

  data: () => ({
    systemToList: [],
    systemTo: null,
    selectedSystem: null,
    selectedRate: {
      default: null,
      type: Rate,
    },

    amountFrom: 1,
    amountTo: 1,
    forceUpdateKey: 0,
  }),

  beforeMount() {
    const firstCanReceiveSystem = this.systemList.find(system => system.canReceive === true);
    this.selectedRate = this.rateList.find(rate => rate.systemFrom.id === firstCanReceiveSystem.id)
    this.amountFrom = this.selectedRate.getMinAmount();
    this.selectedSystemFromUpdated(this.selectedRate.systemFrom)
  },

  mounted() {},

  methods: {
    amountFromUpdated: function(payload) {
      this.convertAmountTo(payload)
    },

    selectedSystemFromUpdated: function(payload) {
      this.changeSystemFrom(payload)
      this.amountFrom = this.selectedRate.getMinAmount()

      this.convertAmountTo(this.amountFrom)
    },

    amountToUpdated: function(payload) {
      this.convertAmountFrom(payload)
    },

    selectedSystemToUpdated: function(payload) {
      this.systemTo = payload
      this.updateShipmentTypes()
      this.updateRate()
      if(!this.selectedRate.validateInput(this.amountFrom)) {
        this.amountFrom = this.selectedRate.getMinAmount()
      }
      this.convertAmountTo(this.amountFrom)

    },

    changeSystemFrom(system) {
      this.selectedSystem = system
      this.systemToList = this.getSystemToList(this.selectedSystem.id)
      this.selectedSystemToUpdated(this.systemToList[0])
    },

    convertAmountFrom(amountTo) {
      this.amountTo = amountTo
      this.amountFrom = this.selectedRate.unConvertPrice(amountTo)
      this.updateStore()
    },

    convertAmountTo(amountFrom) {
      this.amountFrom = amountFrom
      this.amountTo = this.selectedRate.convertPrice(amountFrom)
      this.updateStore()
    },

    getRatesForSystemTo(systemToId) {
      return this.rateList.filter(rate => rate.systemTo === systemToId);
    },

    getSystemToList() {
      const rateList = this.rateList.filter(rate => rate.systemFrom.id === this.selectedSystem.id && rate.systemTo.canSend === true);
      const uniqueSystemIds = [...new Set(rateList.map(rate => rate.systemTo.id))];
      return this.systemList.filter(system => uniqueSystemIds.includes(system.id));
    },

    updateRate() {
      this.selectedRate = this.rateList.find(rate => rate.systemFrom.id === this.selectedSystem.id && rate.systemTo.id === this.systemTo.id);
      this.forceUpdateKey++
      this.$store.commit('updateStoreValue', { variable: 'selected_rate', value: this.selectedRate });
    },

    amountFromBlurred() {
      if(this.selectedRate.validateMinAndMax(this.amountFrom) && !this.selectedRate.validatePrecision(this.amountFrom)) {
        let rounded_down_result = this.selectedRate.getRoundedDownResult(this.amountTo)
        let rounded_up_result = this.selectedRate.getRoundedUpResult(this.amountTo)
        let rounded_down_option = this.selectedRate.getRoundedDownOption(this.amountTo)
        let rounded_up_option = this.selectedRate.getRoundedUpOption(this.amountTo)

        Swal.fire({
          title: 'Oops! Tenemos que pedirte algo...',
          html: 'Sólo podemos darte múltiplos de ' + this.selectedRate.systemTo.roundPrecision + ' en la moneda seleccionada. Necesitamos redondear el monto ingresado a ' + this.roundDecimals(rounded_down_option,2).toString() + ' o a ' + this.roundDecimals(rounded_up_option,2).toString() + '. Por favor, elegí una de las opciones.',
          showDenyButton: true,
          confirmButtonText: this.roundDecimals(rounded_down_option,2).toString(),
          denyButtonText: this.roundDecimals(rounded_up_option,2).toString(),
          allowOutsideClick: false,
          scrollbarPadding: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.amountFrom = rounded_down_option
            this.amountTo = rounded_down_result
          } else if (result.isDenied) {
            this.amountFrom = rounded_up_option;
            this.amountTo = rounded_up_result;
          }

          this.amountFromUpdated(this.amountFrom)
        })
      }
    },

    updateShipmentTypes() {
      this.$store.commit('updateStoreValue', { variable: 'shipment_type_list', value: this.systemTo.shipmentTypes });
    },

    updateStore() {
      this.$store.commit('updateStoreValue', { variable: 'valid_amounts', value: this.selectedRate.validateResult(this.amountTo) });
      this.$store.commit('updateStoreValue', { variable: 'send_amount', value: this.amountTo });
      this.$store.commit('updateStoreValue', { variable: 'receive_amount', value: this.amountFrom });
      this.$store.commit('updateStoreValue', { variable: 'price', value: this.selectedRate.searchPriceByAmount(this.amountFrom) });
    },

    roundDecimals(amount, precision) {
      const round = Math.pow(10, precision);
      return Math.round(amount * round) / round
    }
  },

  watch: {
    "$store.state.selected_rate"(nv) {
      //TODO: ante un nuevo rate, validar si el monto mínimo cambia o no (y si el from queda invalidado)
      //TODO: por ahora si queda invalidado, se disablea el botón
      this.selectedRate = nv
      this.convertAmountTo(this.amountFrom)
    },
  },
}
</script>
